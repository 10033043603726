.ramkat-column__wrap {
    margin-top: 12vw;
}

.ramkat-column__left {

}

.ramkat-column__right {

}

.ramkat-column__block-wrap {
    margin: 40px 0 27px 0;
}

.ramkat-column__block-header {

}

.ramkat-column__block-copy {
    margin-top: 5px;
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}


/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}


/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {

    .ramkat-column__wrap {
        margin-top: -2vw;
    }

    .ramkat-column__left,
    .ramkat-column__right {
        display: inline-block;
        width: 45%;
        vertical-align: top;
    }

    .ramkat-column__block-wrap {
        margin: 0 0 50px 0;
    }

    .ramkat-column__left {

    }

    .ramkat-column__right {
        margin-left: 9%;
    }

    .ramkat-column__block-header {

    }

    .ramkat-column__block-copy {
        ul {
            margin-top: 10px;
        }

    }
}


/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {

    .ramkat-column__wrap {

    }

    .ramkat-column__left,
    .ramkat-column__right {
        width: 42%;
    }

    .ramkat-column__left {

    }

    .ramkat-column__right {
        margin-left: 15%;
    }

    .ramkat-column__block-header {

    }

    .ramkat-column__block-copy {

    }
}


/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
}



////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {

    .ramkat-column__wrap {
        margin-top: -32px;
    }

}


/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {}
