.ramkat-newsletter__form {
    position: relative;
    margin-top: 3vw;
}

.ramkat-newsletter__copy {
    margin-bottom: 0;
}

.ramkat-newsletter__input-wrap {
    position: relative;
}

.ramkat-newsletter__input-text {
    width: 100%;
    background-color: $white;
    border: none;
    border-bottom: 1px solid $black;
    font-family: $cousine;
    color: $black;
    font-size: 3.5vw;
    letter-spacing: 2px;
    padding-bottom: 10px;
    border-radius: 0;
}

.ramkat-newsletter__input-text:placeholder {
    color: $gray;
}

.ramkat-newsletter__submit {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 10px;
}

// Mailchimp overrides
.ramkat-newsletter__response-item {
    background: none !important;
    font-weight: normal !important;
    font-family: $cousine !important;
    font-size: 3.5vw !important;
    margin: 10px 0 !important;
    padding: 0 !important;
}

.ramkat-newsletter__response--error,
.ramkat-newsletter__response--error a {
    color: $red !important;
}

.ramkat-newsletter__response--success {
    color: $gray !important;
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}

/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}

/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {
    .ramkat-newsletter__form {
        width: 50%;
        margin-top: 1vw;
    }

    .ramkat-newsletter__copy {
        width: 50%;
    }

    .ramkat-newsletter__input-text {
        width: 100%;
        font-size: 1.45vw;
        letter-spacing: 1.75px;
    }

    // Mailchimp
    .ramkat-newsletter__response-item {
        font-size: 1.45vw !important;
    }
}

/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {
    .ramkat-newsletter__form {
        margin-top: 1.5vw;
    }

    .ramkat-newsletter__copy {
    }

    .ramkat-newsletter__input-text {
        font-size: 1.1vw;
        padding-bottom: 8px;
        letter-spacing: 1.4px;
    }

    // Mailchimp
    .ramkat-newsletter__response-item {
        font-size: 1.1vw !important;
    }
}

/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
    .ramkat-newsletter__copy,
    .ramkat-newsletter__form {
        width: 49%;
    }

    .ramkat-newsletter__input-text {
        font-size: 1.125vw;
    }

    // Mailchimp
    .ramkat-newsletter__response-item {
        font-size: 1vw !important;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {
    .ramkat-newsletter__input-text {
        font-size: 18px;
    }
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
