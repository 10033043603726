.ramkat-footer {
    margin: 14vw 31px 7vw 31px;
}

.ramkat-footer__wrap {
    padding-bottom: 15px;
    border-bottom: 1px solid $black;
}

.ramkat-footer__general-wrap {

}

.ramkat-footer__address,
.ramkat-footer__email--primary,
.ramkat-footer__secondary-email-wrap,
.ramkat-footer__secondary-wrap {
    margin-bottom: 21px;
}

.ramkat-footer__phone,
.ramkat-footer__social,
.ramkat-footer__email {
    display: block;
}

.ramkat-footer__phone[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

.ramkat-footer__email {

}

.ramkat-footer__email--primary {

}

.ramkat-footer__secondary-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 55%;
}

.ramkat-footer__secondary-email {

}

.ramkat-footer__social {

}

.ramkat-footer__icon-wrap {
    display: inline-block;
    text-align: right;
    width: 41%;
    padding-bottom: 15px;
}

.ramkat-footer__icon {
    width: 24vw;
}

.ramkat-footer__logo--white {
    .theme--white & {
        display: none;
    }
}

.ramkat-footer__logo--black {
    .theme--black & {
        display: none;
    }
}

.ramkat-footer-bottom {
    &__large {
        display: none;
    }
}

.ramkat-footer__badge {
    display: block;
    width: 45vw;
    margin-top: 5vw;
}

.ramkat-footer__copyright {
    margin-top: 2.5vw;
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-smallest} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-medium} {

    .ramkat-footer {
        margin: 8vw 30px 5vw 30px;
    }

    .ramkat-footer__wrap {
        padding-bottom: 42px;
    }

    .ramkat-footer__general-wrap {
        display: inline-block;
        vertical-align: top;
    }

    .ramkat-footer__secondary-wrap {
        display: inline-block;
        vertical-align: top;
        margin-left: 38px;
        width: inherit;
    }

    .ramkat-footer__address {
        margin-bottom: 2vw;
    }

    .ramkat-footer__phone,
    .ramkat-footer__social,
    .ramkat-footer__email {
        display: block;
    }

    .ramkat-footer__email {

    }

    .ramkat-footer__email--primary {

    }

    .ramkat-footer__social {
        margin-bottom: .5vw;
    }

    .ramkat-footer__icon-wrap {
        display: inline-block;
        float: right;
        padding-bottom: 0;
    }

    .ramkat-footer__icon {
        width: 11vw;
        margin-top: 35px;
    }

    .ramkat-footer__copyright {
        margin-top: 4.1vw;
    }

    .ramkat-footer-bottom {
        &__small {
            display: none;
        }

        &__large {
            display: block;
        }

        &__left, &__right {
            display: inline-block;
            vertical-align: top;
            width: 50%;
        }

        &__right {
            text-align: right;
        }
    }

    .ramkat-footer__badge {
        display: inline-block;
        width: 14.7vw;
        margin-top: 4.5vw;
    }
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-large} {

    .ramkat-footer {
        margin: 8vw 39px 5vw 39px;
    }

}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-largest} {

}



////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {

    .ramkat-footer {
        width: 1522px;
        margin: 128px auto 80px auto;
    }

    .ramkat-footer__icon {
        width: 176px;
    }

    .ramkat-footer__copyright {
        margin-top: 65.6px;
    }

}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {}
