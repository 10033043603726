.ramkat-events__wrap {
    margin-top: 8vw;

    .swiper-container {
        display: none;
    }
}

.ramkat-events__event-item {
    margin-bottom: 14vw;
    padding-bottom: 2vw;
    overflow: hidden;
}

.ramkat-events__event-image {
    width: auto;
    min-width: 100%;
    height: 127vw;
    margin-left: 50%;
    transform: translateX(-50%);
}

.ramkat-events__event-date {
    font-family: $cousine;
    margin-top: 3vw;
    font-size: 3.5vw;
    letter-spacing: 3px;
}

.ramkat-events__event-title {
    font-family: $fg-urw;
    margin: 2.25vw 0 1.75vw 0;
    font-size: 3.5vw;
    letter-spacing: 1.5px;
    line-height: 160%;
}

.ramkat-events__event-cta {
    font-family: $fg-urw;
    color: $red;
    text-decoration: none;
    border-bottom: 1px solid $red;
    padding-bottom: 3px;
    font-size: 3.5vw;
    letter-spacing: 2px;
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-smallest} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-medium} {

    .ramkat-events__wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .ramkat-events__event-item {
        position: relative;
        width: 24%;
        margin: 0 3.3px 2.3vw 3.3px;
    }

    .ramkat-events__event-image {
        height: 33.5vw;
        margin-top: 1.5vw;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    .ramkat-events__event-image:hover {
        -webkit-filter: none;
        filter: none;
        -webkit-transition: filter .3s ease-out;
        -moz-transition: filter .3s ease-out;
        transition: filter .3s ease-out;
    }

    .ramkat-events__event-date {
        font-size: 1.5vw;
        margin-top: .7vw;
        letter-spacing: 0;
    }

    .ramkat-events__event-title {
        font-size: 1.55vw;
        margin: .75vw 0 .45vw 0;
    }

    .ramkat-events__event-cta {
        font-size: 1.55vw;
        letter-spacing: 1px;
        -webkit-transition: padding-bottom .3s ease-out;
        -moz-transition: padding-bottom .3s ease-out;
        transition: padding-bottom .3s ease-out;
    }

    .ramkat-events__event-cta:hover {
        padding-bottom: .5vw;
        -webkit-transition: padding-bottom .3s ease-out;
        -moz-transition: padding-bottom .3s ease-out;
        transition: padding-bottom .3s ease-out;
    }
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-large} {

    .ramkat-events__wrap {
        margin-top: 4vw;
    }

    .ramkat-events__event-item {
        margin-bottom: 2.5vw;
        margin: 0 4.4px 2.5vw 4.4px;
    }

    .ramkat-events__event-date,
    .ramkat-events__event-title,
    .ramkat-events__event-cta {
        font-size: 1.3vw;
    }
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-largest} {

    .ramkat-events__event-item {
        margin: 0 5.5px 2.5vw 5.5px;
    }

    .ramkat-events__event-date,
    .ramkat-events__event-title,
    .ramkat-events__event-cta {
        font-size: 1.25vw;
    }

    .ramkat-events__event-date {
        margin-top: 1vw;
    }

    .ramkat-events__event-title {
        margin: .95vw 0;
    }
}




////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {

    .ramkat-events__wrap {
        margin-top: 64px;
    }

    .ramkat-events__event-item {
        margin: 0 5.5px 40px 5.5px;
        padding-bottom: 32px;
    }

    .ramkat-events__event-image {
        height: 536px;
        margin-top: 24px;
    }

    .ramkat-events__event-date,
    .ramkat-events__event-title,
    .ramkat-events__event-cta {
        font-size: 20px;
    }

    .ramkat-events__event-date {
        margin-top: 16px;
    }

    .ramkat-events__event-title {
        margin: 16px 0;
    }

}


/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {}
