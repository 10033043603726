.ramkat-faq__wrap {

}

.ramkat-faq__faq-block {
    margin-bottom: 50px;
}

.ramkat-faq__faq-question {

}

.ramkat-faq__faq-answer {

    p {
        margin: 0;
    }
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}


/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}


/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {

    .ramkat-faq__wrap {

    }

    .ramkat-faq__faq-block {
        margin-bottom: 50px;
    }

    .ramkat-faq__faq-question,
    .ramkat-faq__faq-answer {
        display: inline-block;
        vertical-align: top;

    }

    .ramkat-faq__faq-question {
        width: 49%;
    }

    .ramkat-faq__faq-answer {
        margin-left: 27px;
        width: 45%;
    }
}


/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {

}


/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {

    .ramkat-faq__faq-question {
        width: 40%;
    }

    .ramkat-faq__faq-answer {
        margin-left: 90px;
        width: 49%;
    }
}


/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {}
