.ramkat-rentals-form__wrap {
    position: relative;
}

.ramkat-rentals-form__form {
    overflow: hidden;
    transition: height 1000ms ease 0ms, opacity 500ms linear 0ms;

    @include placeholder(input) {
        color: $black;
    }

    @include placeholder(textarea) {
        color: $black;
    }

    input, select, textarea {
        &.error {
            outline: $red auto 1px;
        }
    }

    &.ramkat-rentals-form__form--close {
        height: 0 !important;
        opacity: 0;

    }

    &.ramkat-rentals-form__form--hide {
        display: none;
    }
}

.ramkat-rentals-form__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > * {
        width: 100%;
    }
}

.ramkat-rentals-form__input,
.ramkat-rentals-form__select,
.ramkat-rentals-form__textarea {
    display: block;
    width: 100%;
    margin-bottom: 3.5vw;
    padding: 4vw 3vw 3.5vw;
    border: 1px solid $black;
    border-radius: 0;
    box-sizing: border-box;
    font-family: $cousine;
    font-size: 3.5vw;
    letter-spacing: 2px;
    color: $black;
    background-color: $white;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.ramkat-rentals-form__select-container {
    position: relative;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        width: 2.5vw;
        height: calc(100% - 3.5vw);
        position: absolute;
        top: 0; right: 2vw;
        background-image: url("../images/angle-down_black.svg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        pointer-events: none;
    }
}

.ramkat-rentals-form__select {
    padding: 4vw 6vw 3.5vw 3vw;
    cursor: pointer;
}

.ramkat-rentals-form__options {
}

.ramkat-rentals-form__textarea {
    min-height: 25vw;
    resize: none;
    // padding: 4vw 3vw 3vw;
}

.ramkat-rentals-form__recaptcha {
    margin-top: 4vw;
}

.ramkat-rentals-form__submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 6vw 0;
    padding: 0;
    border: none;
    position: relative;
    font-family: $ag;
    font-size: 8vw;
    letter-spacing: .2em;
    background: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        .ramkat-rentals-form__submit-arrow {
            animation: bounce 1s ease 0s infinite;
        }
    }
}

.ramkat-rentals-form__submit-arrow {
    width: 36vw;
    max-width: 50%;
    height: 2.28vw;
    background-image: url("../images/arrow-long_black.svg");
    background-repeat: no-repeat;
    background-position: 0px center;
    transition: background 600ms linear 0ms;
}

#ramkat-rentals-form__thank-you-message {
    display: none;
    opacity: 0;
    transition: opacity 500ms linear 0ms;

    &.ramkat-rentals-form__thank-you-message--show {
        display: block;
    }

    &.ramkat-rentals-form__thank-you-message--open {
        opacity: 1;
    }
}

#ramkat-rentals-form__fail-message {
    display: none;
    color: $red;

    a {
        font-weight: bold;
    }
}

@keyframes bounce {
    0%   { transform: translateX(0); }
    50%  { transform: translateX(-8px); }
    100% { transform: translateX(0); }
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}

/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}

/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {
    .ramkat-rentals-form__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > * {
            width: 49.4%;
        }
    }

    .ramkat-rentals-form__input {
        display: inline-block;
        width: 49.4%;
    }

    .ramkat-rentals-form__input,
    .ramkat-rentals-form__select,
    .ramkat-rentals-form__textarea {
        font-size: 1.3vw;
        padding: 1vw 0 1vw 2vw;
        margin-bottom: 2vw;
    }

    .ramkat-rentals-form__select-container {
        &::after {
            width: .7vw;
            height: calc(100% - 2vw);
            right: 1vw;
        }
    }

    .ramkat-rentals-form__select {
        display: inline;
        position: relative;
        border-radius: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    .ramkat-rentals-form__select option {
        font-family: $cousine;
        font-size: 1.3vw;
        letter-spacing: 2px;
        background-color: $black;
        color: $white;
    }

    .ramkat-rentals-form::after {
        right: 2%;
        top: 15%;
        position: absolute;
        display: inline-block;
        content: "∟";
        font-family: $cousine;
        font-size: 1.5vw;
        font-weight: 900;
        transform: scale(-1) rotate(135deg) translateZ(0px);
        backface-visibility: hidden;
    }

    .ramkat-rentals-form__textarea {
        min-height: 10vw;
    }

    .ramkat-rentals-form__result {
        margin: 4vw 0 2vw;
    }

    .ramkat-rentals-form__recaptcha {
        margin-top: 2vw;
    }

    .ramkat-rentals-form__submit {
        width: 49.4%;
        margin: 4vw 0;
        font-size: 3.5vw;
    }

    .ramkat-rentals-form__submit-arrow {
        width: 24vw;
        max-width: 53%;
        height: 1.52vw;
    }
}

/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {
    .ramkat-rentals-form__input {
        display: inline-block;
        width: 49.47%;
    }

    .ramkat-rentals-form__input,
    .ramkat-rentals-form__select,
    .ramkat-rentals-form__textarea {
        font-size: 1vw;
        margin-bottom: 1vw;
    }

    .ramkat-rentals-form__select-container {
        &::after {
            height: calc(100% - 1vw);
            right: .7vw;
        }
    }

    .ramkat-rentals-form__select {
        font-size: 1vw;
    }

    .ramkat-rentals-form::after {
        font-size: 1.4vw;
        top: 14%;
    }

    .ramkat-rentals-form__textarea {
    }

    .ramkat-rentals-form__recaptcha {
        margin-top: 1vw;
    }

    .ramkat-rentals-form__submit {
        margin: 3vw 0;
    }
}

/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
    .ramkat-rentals-form__input,
    .ramkat-rentals-form__select,
    .ramkat-rentals-form__textarea {
        font-size: 1.125vw;
    }

    .ramkat-rentals-form__select {
        font-size: 1vw;
        padding: 1.2vw 0 1.2vw 2vw;
    }

    .ramkat-rentals-form::after {
        font-size: 1.2vw;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {
    .ramkat-rentals-form__input,
    .ramkat-rentals-form__select,
    .ramkat-rentals-form__textarea {
        font-size: 18px;
        margin-bottom: 16px;
        padding: 16px 0 16px 32px;
    }

    .ramkat-rentals-form::after {
        font-size: 19px;
    }
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
