.ramkat-home__wrap {

}

.ramkat-home__events-copy-wrap {
    margin-bottom: 15vw;
}

.swiper-container {
    width: 98%;
    height: 100%;
    overflow: visible;
    z-index: 1;
}

.swiper-slide {
    max-width: 100%;
    text-align: center;
    overflow: hidden;
}

.swiper-slide-prev,
.swiper-slide-next {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.ramkat-home__event-image {
    width: auto;
    min-width: 100%;
    height: 127vw;
    overflow: hidden;
    margin-left: 50%;
    transform: translateX(-50%);
    filter: brightness(90%);
}

.ramkat-home__events-wrap {
    margin: 0;
    padding: 0;

    .ramkat-events__desktop-grid {
        display: none;
    }
}

.ramkat-home__full-image-wrap {
    width: 100%;
    overflow: hidden;
    margin-top: 15vw;
}

.ramkat-home__full-image {

}

.ramkat-events__event-copy-wrap {
    position: absolute;
    bottom: 12vw;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
}


.ramkat-home__history-copy-wrap {
    margin-top: 6vw;
}

.ramkat-home__event-date,
.ramkat-home__event-title,
.ramkat-home__event-cta {
    color: $white;
}

.ramkat-home__event-cta {
    border-bottom: 1px solid $white;
}

.ramkat-home__history-header {
    width: 90%;
}

.ramkat-home__history-body-text {
    margin-top: 5vw;
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-smallest} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-medium} {

    .swiper-container {
        display: none;
    }

    .swiper-slide {
        display: none;
    }

    .ramkat-home__events-wrap {
        display: flex;
        flex-wrap: wrap;

        .ramkat-events__desktop-grid {
            display: block;
        }
    }

    .ramkat-home__copy-wrap {
        display: inline-block;
    }

    .ramkat-home__events-copy-wrap {
        width: 50%;
    }

    .ramkat-home__body-text {
        width: 90%;
        margin-bottom: 2vw;
    }

    .ramkat-home__full-image-wrap {
        margin-top: 7vw;
        overflow: visible;
    }

    .ramkat-home__full-image {
        width: 100%;
        float: none;
    }

    .ramkat-home__history-copy-wrap {
        margin-top: 4.8vw;
    }

    .ramkat-home__history-header,
    .ramkat-home__history-body-text {
        display: inline-block;
        width: 49.5%;
    }

    .ramkat-home__history-header {
        vertical-align: top;
    }

    .ramkat-home__history-body-text {
        margin: .5vw 0 2vw 0;
    }

}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-large} {

    .ramkat-home__wrap {

    }

    .ramkat-home__body-text {
        width: 93%;
    }

}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-largest} {

}



////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {

    .ramkat-home__full-image-wrap {
        margin-top: 112px;
    }

    .ramkat-home__full-image {
        width: 1522px;
    }

}


/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {}
