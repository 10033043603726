.ramkat__body-text,
.ramkat__body-pair {

}

.ramkat__body-text {
    margin-bottom: 8vw;
}

.ramkat__body-text--alternative {
    font-family: $ag !important;
    letter-spacing: .15em !important;
}

.ramkat__full-image-wrap {
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.ramkat__full-image {
    width: auto;
    min-width: 100%;
    height: 85vw;
    margin-left: 50%;
    transform: translateX(-50%);
}

.ramkat__image-pair {
    width: 100%;
    text-align: center;
}

.ramkat__image-pair--left {
    margin-bottom: 4vw;
}

.ramkat-body__wrap {
    .ramkat__body-text:first-child + .ramkat__body-pair {
        margin-top: 12vw;
    }

    .ramkat__body-pair + .ramkat__body-pair  {
        margin: 6vw 0 0;
    }

    .ramkat__body-pair + .ramkat__full-image-wrap,
    .ramkat__body-pair + .ramkat__image-pair-wrap {
        margin-top: 4vw;
    }

    .ramkat__full-image-wrap + .ramkat__body-text {
        margin: 6vw 0;
    }

    .ramkat__full-image-wrap + .ramkat__full-image-wrap {
        margin: 4vw 0;
    }

    .ramkat__full-image-wrap + .ramkat__image-pair-wrap {
        margin-top: 4vw;
    }

    .ramkat__image-pair-wrap + .ramkat__body-text {
        margin: 6vw 0;
    }

    .ramkat__image-pair-wrap + .ramkat__body-pair {
        margin-top: 6vw;
    }

    .ramkat__image-pair-wrap + .ramkat__full-image-wrap {
        margin: 4vw 0;
    }

    .ramkat__image-pair-wrap + .ramkat__image-pair-wrap {
        margin-top: 4vw;
    }

}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-smallest} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-medium} {
    .ramkat__body-text,
    .ramkat__body-pair {
        width: 50%;
    }

    .ramkat__full-image-wrap {
        overflow: hidden;
    }

    .ramkat__full-image {
        width: 100%;
        height: auto;
    }

    .ramkat__image-pair-wrap {
        display: flex;
        justify-content: space-between;
    }

    .ramkat-body__wrap {
        .ramkat__body-text:first-child + .ramkat__body-pair {
            margin-top: -2vw;
        }

        .ramkat__image-pair-wrap + .ramkat__image-pair-wrap {
            margin-top: 1vw;
        }
    }

    .ramkat__image-pair {
        width: 49.5%;
        align-self: center;
    }

    .ramkat__image-pair--left {
        margin-bottom: 0;
    }

}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-large} {

    .ramkat__body-text {
        margin-bottom: 6vw;
    }

    .ramkat-body__wrap {
        .ramkat__full-image-wrap + .ramkat__full-image-wrap {
            margin: 2.2vw 0;
        }

        .ramkat__full-image-wrap + .ramkat__image-pair-wrap {
            margin-top: 2.2vw;
        }

        .ramkat__image-pair-wrap + .ramkat__full-image-wrap {
            margin: 2.2vw 0;
        }

        .ramkat__image-pair-wrap + .ramkat__image-pair-wrap {
            margin-top: 1vw;
        }
    }

}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-largest} {
}



////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {

    // .ramkat__body-text {
    //     margin-bottom: 96px;
    // }

    // .ramkat-body__wrap {
    //     .ramkat__full-image-wrap + .ramkat__body-text {
    //         margin: 89px 0;
    //     }

    //     .ramkat__full-image-wrap + .ramkat__full-image-wrap {
    //         padding: 35.2px 0;
    //     }

    //     .ramkat__full-image-wrap + .ramkat__image-pair-wrap {
    //         margin-top: 35.2px;
    //     }

    //     .ramkat__image-pair-wrap + .ramkat__body-text {
    //         margin: 89px 0;
    //     }
    // }

}


/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {}
