.ramkat-contact-form__wrap {
    position: relative;
}

.ramkat-contact-form__name,
.ramkat-contact-form__email,
.ramkat-contact-form__textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-family: $cousine;
    font-size: 3.5vw;
    letter-spacing: 2px;
    border: 1px solid $white;
    background-color: $black;
    color: $white;
    padding: 4vw 0 3.5vw 3vw;
    margin-bottom: 3.5vw;
}

.ramkat-contact-form__select {
    display: none;
    z-index: 0;
    cursor: pointer;
}

.ramkat-contact-form__select::-ms-expand {
    display: none;
}

.ramkat-contact-form__options {
}

.ramkat-contact-form__textarea {
    resize: none;
    padding: 6vw 0 0 3vw;
}

.ramkat-contact-form__cta {
    font-family: $ag;
    text-transform: uppercase;
    font-size: 3.75vw;
    letter-spacing: 3px;
    color: $white;
    background-color: $black;
    border: none;
    padding: 1vw 0 0 0;
    -webkit-appearance: none;
}

.ramkat-contact-form__cta:hover {
    cursor: pointer;
}

/*************************************************************
 ** 320px Enhanced - Ideal for mobile                       **
 ** Inherits from 320px standard                            **
 ** Media query checks for CSS3 support. Has touch support. **
 *************************************************************/

@media #{$mq-smallest} {
}

/*******************************************************
 ** 480px - Ideal for iPhone Landscape, other mobiles **
 ** Inherits from 320px Standard, and 320px Enhanced  **
 ** Has touch support                                 **
 *******************************************************/

@media #{$mq-small} {
}

/*************************************************************
 ** 720px - Ideal for iPad Portrait                         **
 ** Inherits from 320px Standard, 320px Enhanced, and 480px **
 ** Has touch support.                                      **
 *************************************************************/

@media #{$mq-medium} {
    .ramkat-contact-form__name,
    .ramkat-contact-form__email {
        display: inline-block;
        width: 49.4%;
    }

    .ramkat-contact-form__email {
        margin-left: 0.6%;
    }

    .ramkat-contact-form__name,
    .ramkat-contact-form__email,
    .ramkat-contact-form__textarea,
    .ramkat-contact-form__select {
        font-size: 1.3vw;
        padding: 1vw 0 1vw 2vw;
        margin-bottom: 2vw;
    }

    .ramkat-contact-form__select {
        display: inline;
        font-family: $cousine;
        letter-spacing: 2px;
        position: relative;
        width: 100%;
        background-color: $black;
        border: 1px solid $white;
        color: $white;
        border-radius: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    .ramkat-contact-form:after {
        right: 2%;
        top: 15%;
        position: absolute;
        display: inline-block;
        content: "∟";
        font-family: $cousine;
        font-size: 1.5vw;
        font-weight: 900;
        transform: scale(-1) rotate(135deg) translateZ(0px);
        backface-visibility: hidden;
    }

    .ramkat-contact-form__options option {
        font-family: $cousine;
        font-size: 1.3vw;
        letter-spacing: 2px;
        background-color: $black;
        color: $white;
    }

    .ramkat-contact-form__textarea {
    }

    .ramkat-contact-form__cta {
        font-size: 1.3vw;
        padding: 2vw 0 0 0;
        transition: 0.8s ease;
        cursor: pointer;
    }

    .ramkat-contact-form__cta:hover {
        margin-right: 2vw;
        transition: 0.8s ease;
    }

    .ramkat-contact-form__result {
        margin: 4vw 0 2vw;
    }
}

/************************************************************
 ** 960px - Ideal for most browser screens, iPad Landscape **
 ** Inherits all the things.                               **
 ** Has touch support.                                     **
 ************************************************************/

@media #{$mq-large} {
    .ramkat-contact-form__name,
    .ramkat-contact-form__email {
        display: inline-block;
        width: 49.47%;
    }

    .ramkat-contact-form__name,
    .ramkat-contact-form__email,
    .ramkat-contact-form__textarea,
    .ramkat-contact-form__select {
        font-size: 1vw;
        margin-bottom: 1vw;
    }

    .ramkat-contact-form__select {
        font-size: 1vw;
    }

    .ramkat-contact-form:after {
        font-size: 1.4vw;
        top: 14%;
    }

    .ramkat-contact-form__textarea {
    }

    .ramkat-contact-form__cta {
        font-size: 1vw;
    }
}

/**********************************************
 ** 1200px - Ideal for wider browser screens **
 ** Inherits all the things.                 **
 ** Has touch support.                       **
 **********************************************/

@media #{$mq-largest} {
    .ramkat-contact-form__name,
    .ramkat-contact-form__email,
    .ramkat-contact-form__textarea,
    .ramkat-contact-form__select {
        font-size: 1.125vw;
    }

    .ramkat-contact-form__select {
        font-size: 1vw;
        padding: 1.2vw 0 1.2vw 2vw;
    }

    .ramkat-contact-form:after {
        font-size: 1.2vw;
    }

    .ramkat-contact-form__cta {
        font-size: 1.125vw;
        letter-spacing: 3px;
        margin-right: 0.5vw;
    }

    .ramkat-contact-form__cta:hover {
        margin-right: 1vw;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {
    .ramkat-contact-form__name,
    .ramkat-contact-form__email,
    .ramkat-contact-form__textarea,
    .ramkat-contact-form__select {
        font-size: 18px;
        margin-bottom: 16px;
        padding: 16px 0 16px 32px;
    }

    .ramkat-contact-form:after {
        font-size: 19px;
    }

    .ramkat-contact-form__cta {
        font-size: 18px;
        margin-right: 8px;
        padding-top: 32px;
    }
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
