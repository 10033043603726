$fg-urw: 'franklin-gothic-urw', sans-serif;
$ag: 'aktiv-grotesk-extended', sans-serif;
$din: 'din-2014', sans-serif;
$cousine: 'Cousine', monospace;

$red: #C34B44;
$white: #F5F5F5;
$black: #191919;
$gray: #9B9B9B;

@mixin placeholder($selector) {
	#{$selector}:-moz-placeholder { @content; }
	#{$selector}::-moz-placeholder { @content; }
	#{$selector}:-ms-input-placeholder { @content; }
	#{$selector}::-webkit-input-placeholder { @content; }
	#{$selector}::placeholder { @content; }
}