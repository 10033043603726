@import "media-queries";
@import "variables";
@import "css/column-text";
@import "css/contact-form";
@import "css/content-blocks";
@import "css/events";
@import "css/faq";
@import "css/footer";
@import "css/header";
@import "css/hero";
@import "css/home";
@import "css/newsletter";
@import "css/rentals-form";
@import "css/tech";

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    position: relative;
    height: 100%;
}

.theme--white {
    background-color: $white;
    color: $black;

    .ramkat__link {
        color: $black;
    }

    .ramkat-footer__wrap {
        border-bottom: 1px solid $black;
    }

    .ramkat-header__nav-page-title {
        border-left: 1px solid $black;
    }

    .ramkat__copy--small {
        a {
            color: $red;
            text-decoration: none;
            border-bottom: 1px solid $red;
        }
        .ramkat__cta {
            a {
                color: $black;
                border-bottom: none;
            }
        }
    }

    .ramkat-footer__credit {
        p {
            a {
                color: $black;
                text-decoration: none;
                border-bottom: none;
            }
        }
    }

    .ramkat__cta::after {
        background-image: url("../images/arrow_black.svg");
    }
}

.theme--black {
    background-color: $black;
    color: $white;

    .ramkat-home__wrap {
        color: $black;

        .ramkat__copy--small p a {
            color: $black;
        }

        .ramkat__cta::after {
            background-image: url("../images/arrow_black.svg");
        }
    }

    .ramkat__link {
        color: $white;
    }

    .ramkat-footer__wrap {
        border-bottom: 1px solid $white;
    }

    .ramkat-header__nav-page-title {
        border-left: 1px solid $white;
    }

    .ramkat__copy--small {
        p {
            a {
                color: $white;
                text-decoration: none;
                border-bottom: 1px solid $white;
            }
        }

        .ramkat__cta {
            a {
                border-bottom: none;
            }
        }
    }

    .ramkat__cta::after {
        background-image: url("../images/arrow_white.svg");
    }

    .ramkat-footer__credit {
        p {
            a {
                color: $white;
                text-decoration: none;
                border-bottom: none;
            }
        }
    }
}

.ramkat__body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.ramkat__body--freeze {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    //when the nav is open, the white logo will show
    .ramkat-header__logo--black {
        display: none;
    }

    .ramkat-header__logo--white {
        display: inherit;
    }
}

.ramkat-nav-wrap + .ramkat-body__wrap {
    position: fixed;
    overflow: hidden;
}

.ramkat__overflow-wrap {
    overflow-x: hidden;
}

.ramkat-body__wrap {
    margin: 5vw 31px 0 31px;
    display: block;
    min-height: 55vh;
}

.ramkat__header {
    font-family: $din;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 9.5vw;
    line-height: 150%;
    letter-spacing: 4.5px;
    margin-bottom: 2.2vw;
}

.ramkat__copy--large {
    font-family: $din;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 180%;
    font-size: 5.65vw;
    letter-spacing: 3.5px;
    z-index: -1;
}

.ramkat__copy--small {
    font-family: $fg-urw;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    font-size: 3.75vw;
    letter-spacing: 1px;
    z-index: -1;

    ul {
        padding-left: 19px;

        li {
            padding-left: 3vw;
        }

        li:before {
            font-size: 7px;
        }
    }

    p {
        margin: 0;

        a {
            padding-bottom: 1vw;
            margin-bottom: 5vw;
        }
    }
}

.ramkat__link {
    text-decoration: none;
}

.ramkat__cta {
    text-decoration: none;
    font-family: $ag;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 3.75vw;
    padding-top: 3.5vw;
}

.ramkat__cta::after {
    content: "";
    background-repeat: no-repeat;
    display: inline-block;
    width: 5vw;
    height: 3.5vw;
    vertical-align: middle;
    margin-left: 2vw;
}

.ramkat__arrow {
    width: 5vw;
}

.ramkat__visit-faq {
    margin-top: 12vw;
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-smallest} {
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small} {
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-medium} {
    .ramkat-body__wrap {
        margin-top: 1.6vw;
        margin: 0 30px;
        min-height: 65vh;
    }

    .ramkat__body {
        overflow-x: hidden;
    }

    .ramkat__header {
        font-size: 3.9vw;
        margin-bottom: 3.5vw;
    }

    .ramkat__copy--large {
        font-size: 2.35vw;
        letter-spacing: 2.5px;
    }

    .ramkat__copy--small {
        font-size: 1.55vw;
        line-height: 195%;

        ul {
            li {
                padding-left: 1.5vw;
            }
        }

        p {
            a {
                padding-bottom: 0.2vw;
            }

            a:hover {
                padding-bottom: 0.3vw;
            }
        }
    }

    .ramkat__cta {
        font-size: 1.55vw;
        padding-top: 2vw;
        letter-spacing: 2px;
    }

    .ramkat__cta::after {
        width: 2vw;
        height: 1.5vw;
        margin-left: 1.5vw;
        transition: 0.8s ease;
    }

    .ramkat__cta:hover:after {
        margin-left: 2.2vw;
        transition: 0.8s ease;
    }

    .ramkat__arrow {
        width: 2vw;
    }

    .ramkat__visit-faq {
        margin-top: 10.5vw;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-large} {
    .ramkat-body__wrap {
        margin: 0 39px;
        min-height: 56vh;
    }

    .ramkat__body {
    }

    .ramkat__header {
        font-size: 4vw;
        letter-spacing: 4px;
    }

    .ramkat__copy--large {
        font-size: 2vw;
        letter-spacing: 2.8px;
    }

    .ramkat__copy--small {
        font-size: 1.3vw;
        line-height: 220%;

        a:hover {
            padding-bottom: 0.4vw;
        }
    }

    .ramkat__cta {
        font-size: 1.2vw;
    }

    .ramkat__cta::after {
        width: 1.5vw;
        height: 1vw;
    }

    .ramkat__arrow {
        width: 1.5vw;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-largest} {
    .ramkat-body__wrap {
        margin-top: 4.25vw;
    }

    .ramkat__header {
        font-size: 3.75vw;
        letter-spacing: 6px;
        margin-bottom: 0.5vw;
    }

    .ramkat__copy--large {
        font-size: 2.1vw;
    }

    .ramkat__copy--small {
        font-size: 1.25vw;
        letter-spacing: 1.5px;
    }

    .ramkat__cta {
        font-size: 1.125vw;
        letter-spacing: 3px;
    }

    .ramkat__cta::after {
        width: 1.5vw;
        height: 1vw;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {
    .ramkat-body__wrap {
        width: 1522px;
        height: 100%;
        margin: 68px auto 0 auto;
    }

    .ramkat__header {
        font-size: 60px;
        margin-bottom: 8px;
    }

    .ramkat__copy--large {
        font-size: 33.6px;
    }

    .ramkat__copy--small {
        font-size: 20px;
    }

    .ramkat__cta {
        font-size: 18px;
    }

    .ramkat__cta::after {
        width: 23px;
        height: 15px;
        margin-left: 24px;
    }

    .ramkat__arrow {
        width: 23px;
    }

    .ramkat__visit-faq {
        margin-top: 168px;
    }
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
