.ramkat-header__wrap {
    height: calc(30px + 10vw);
}

.ramkat-header__nav-header {
    display: block;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    position: fixed;
    z-index: 50;
    pointer-events: none;

    & > * {
        pointer-events: auto;
    }

    .theme--black & {
        background-color: rgba($black, 80%);
    }

    .theme--white & {
        background-color: rgba($white, 80%);
    }
}

.ramkat-header__nav-header--transparent {
    background: none !important;
}

.ramkat-header__nav-hamburger {
    width: 5.3vw;
    height: 4vw;
    margin-top: 2.7vw;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.ramkat-header__nav-line {
    display: block;
    width: 100%;
    position: relative;
    left: 0;
    height: 1px;
    background: $black;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    .ramkat-header__nav-hamburger--black &,
    .theme--black & {
        background-color: $white;
    }

    .ramkat-header__nav-hamburger--white &,
    .theme--white & {
        background-color: $black;
    }
}

.ramkat-header__nav-line:nth-child(2) {
    width: 0;
    height: 0;
}

.ramkat-header__nav-line:nth-child(1) {
    top: 0px;
}

.ramkat-header__nav-line:nth-child(2),
.ramkat-header__nav-line:nth-child(3) {
    top: 1vw;
}

.ramkat-header__nav-line:nth-child(4) {
    top: 2vw;
}

.ramkat-header__nav-hamburger--open .ramkat-header__nav-line:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.ramkat-header__nav-hamburger--open .ramkat-header__nav-line:nth-child(2) {
    transform: rotate(45deg);
    background-color: $white;
    width: 100%;
    height: 1px;
}

.ramkat-header__nav-hamburger--open .ramkat-header__nav-line:nth-child(3) {
    transform: rotate(-45deg);
    background-color: $white;
    margin-top: -0.5vw;
}

.ramkat-header__nav-hamburger--open .ramkat-header__nav-line:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.ramkat-header__nav-hamburger--open + .ramkat-header__nav-page-title {
    display: none;
    z-index: -1;
}

.ramkat-header__nav-page-title {
    font-family: $fg-urw;
    font-style: normal;
    font-weight: 400;
    font-size: 3.75vw;
    letter-spacing: 2px;
    padding: 0.6vw 0 0.6vw 3.5vw;
    margin: 1.5vw 0 0 9vw;
    position: relative;
    vertical-align: middle;
    display: inline-block;
}

.ramkat-header__logo-wrap {
    display: inline-block;
    position: absolute;
    right: 30px;
    cursor: pointer;
}

.ramkat-header__logo {
    width: 35vw;
    height: 75px;
    background-repeat: no-repeat;
    display: block;
    position: relative;
    right: 0;
}

.ramkat-header__logo--white {
    .theme--white & {
        display: none;
    }
}

.ramkat-header__logo--black {
    .theme--black & {
        display: none;
    }
}

.ramkat-header__main-nav--closed {
    display: none;
}

.ramkat-nav-wrap {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 0 0 31px;
    box-sizing: border-box;
    background-color: $black;
    transition: 0.5s ease-in-out;
    z-index: 10;
    overflow: hidden;
}

.ramkat-header__nav {
    position: absolute;
    top: 54%;
    transform: translateY(-50%);
}

.ramkat-main-nav__title {
    font-family: $din;
    font-weight: 600;
    font-size: 9.5vw;
    line-height: 130%;
    letter-spacing: 6px;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    color: $black;
    text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
    user-select: none;
}

.ramkat-main-nav__title:hover,
.ramkat-main-nav__title--active {
    color: $white;
    transition: 0.5s ease-in-out;
}

.ramkat-sub-nav__wrap {
}

.ramkat-sub-nav__title-wrap {
    margin-bottom: 0;
    line-height: 75%;
}

.ramkat-sub-nav__title {
    font-family: $fg-urw;
    color: $white;
    text-decoration: none;
    font-size: 2.7vw;
    letter-spacing: 2px;
    border-bottom: 1px solid $white;
    padding-bottom: 0;
    user-select: none;
}

.ramkat-contact-nav__wrap {
    margin-top: 20px;
}

.ramkat-contact-nav__info {
    color: $white;
    font-size: 3vw;
    font-family: $fg-urw;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    letter-spacing: 0.6px;
    z-index: -1;

    a {
        color: $white;
        text-decoration: none;
    }
}

.ramkat-contact-nav__info--address {
    margin-bottom: 15px;
}

.ramkat-header__nav-image-wrap {
    display: none;
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-smallest} {
    .ramkat-nav-wrap {
        margin-bottom: -10px;
    }
}

@media #{$mq-smallest} and (max-height: 568px) {

    .ramkat-header__nav-hamburger {
        width: 6vw;
    }

    .ramkat-header__nav-line:nth-child(2),
    .ramkat-header__nav-line:nth-child(3) {
        top: 1.3vw;
    }

    .ramkat-header__nav-line:nth-child(4) {
        top: 2.5vw;
    }


    .ramkat-main-nav__title {
        font-size: 8.2vw;
        line-height: 143%;
    }

    .ramkat-sub-nav__title-wrap {
        line-height: 60%;
    }

    .ramkat-sub-nav__title {
        font-size: 2.9vw;
    }

    .ramkat-contact-nav__wrap {
        margin-top: 15px;
    }

    .ramkat-contact-nav__info {
        font-size: 2.9vw;
        line-height: 180%;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small} {
    .ramkat-header__nav-hamburger--open .ramkat-header__nav-line:nth-child(3) {
        margin-top: -0.25vw;
    }

    .ramkat-sub-nav__title {
        line-height: 100%;
    }
}

@media #{$mq-small} and (max-height: 550px) {
    .ramkat-header__nav {
        width: 90%;
    }

    .ramkat-header__nav-hamburger {
        width: 4.3vw;
        margin-top: 1.7vw;
    }

    .ramkat-header__nav-page-title {
        margin: 0.5vw 0 0 9vw;
    }

    .ramkat-header__logo {
        width: 25vw;
    }

    .ramkat-main-nav__wrap,
    .ramkat-sub-nav__wrap {
        display: inline-block;
        width: 48%;
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
    }

    .ramkat-main-nav__title {
        font-size: 8vw;
        line-height: 140%;
        text-align: center;
    }

    .ramkat-sub-nav__wrap {
        right: 0;
    }

    .ramkat-sub-nav__title {
        font-size: 2vw;
        line-height: 70%;
    }

    .ramkat-contact-nav__info {
        font-size: 2vw;
        line-height: 200%;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small-plus} and (max-height: 800px) {
    .ramkat-main-nav__title {
        font-size: 6vw;
    }

    .ramkat-sub-nav__title {
        font-size: 1.7vw;
        line-height: 90%;
    }

    .ramkat-contact-nav__info {
        font-size: 1.7vw;
    }
}

@media #{$mq-small-plus} and (min-height: 801px) {
    .ramkat-main-nav__title {
        font-size: 8vw;
        line-height: 160%;
    }

    .ramkat-sub-nav__title {
        font-size: 2vw;
        line-height: 100%;
    }

    .ramkat-contact-nav__info {
        font-size: 2vw;
    }
}

@media #{$mq-small-plus} and (min-height: 990px) {
    .ramkat-main-nav__title {
        font-size: 11vw;
    }

    .ramkat-sub-nav__title {
        font-size: 2.5vw;
        line-height: 130%;
    }

    .ramkat-contact-nav__info {
        font-size: 2.5vw;
        line-height: 200%;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-medium} {
    .ramkat-header__wrap {
        height: 13vw;
    }

    .ramkat-header__nav-header {

    }

    .ramkat-header__nav-hamburger {
        width: 4vw;
        height: 3vw;
        margin-top: 0.4vw;
    }

    .ramkat-header__nav-line:nth-child(2),
    .ramkat-header__nav-line:nth-child(3) {
        top: 0.8vw;
    }

    .ramkat-header__nav-line:nth-child(4) {
        top: 1.6vw;
    }

    .ramkat-header__nav-hamburger--open .ramkat-header__nav-line:nth-child(3) {
        margin-top: -0.1vw;
    }

    .ramkat-header__nav-page-title {
        font-size: 1.55vw;
        letter-spacing: 1px;
        padding: 0.5vw 0 0.5vw 2vw;
        margin: 0 0 0 6.5vw;
    }

    .ramkat-nav-wrap {
        padding: 0 0 0 14.5%;
    }

    .ramkat-header__nav {
        width: 100%;
        top: 49.5%;
    }

    .ramkat-header__logo-wrap {
        right: 40px;
    }

    .ramkat-header__logo {
        width: 17vw;
    }

    .ramkat-main-nav__wrap {
        display: inline-block;
        width: 49.5%;
    }

    .ramkat-main-nav__title {
        font-size: 10vw;
        line-height: 165%;
        letter-spacing: 8px;
    }

    .ramkat-sub-nav__wrap {
        display: inline-block;
        width: 39%;
        vertical-align: bottom;
        margin-bottom: 86px;
        z-index: 10;
    }

    .ramkat-sub-nav__title {
        font-size: 1.55vw;
        line-height: 100%;
        letter-spacing: 1px;
    }

    .ramkat-contact-nav__wrap {
        margin: 53px 0 0 0;
    }

    .ramkat-contact-nav__info {
        font-size: 1.55vw;
        letter-spacing: 1px;
        line-height: 220%;
    }

    .ramkat-contact-nav__info--address {
        margin-bottom: 24px;
    }

    .ramkat-header__nav-image-wrap {
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0;
        z-index: -1;
    }

    .ramkat-header__nav-image {
        position: absolute;
        height: 100%;
        width: 35%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        left: 57.5%;
    }
}

@media #{$mq-medium} and (max-height: 850px) {
    .ramkat-main-nav__title {
        font-size: 6.7vw;
        text-align: inherit;
    }

    .ramkat-sub-nav__wrap {
        margin-bottom: 15px;
        width: 48%;
    }

    .ramkat-contact-nav__wrap {
        margin: 30px 0 0 0;
    }
}

@media #{$mq-medium} and (min-height: 851px) {
    .ramkat-sub-nav__title {
        font-size: 1.7vw;
    }

    .ramkat-contact-nav__info {
        font-size: 1.4vw;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-large} {
    .ramkat-header__wrap {
    }

    .ramkat-header__nav-hamburger {
        width: 3vw;
        margin-top: 1.1vw;
    }

    .ramkat-header__nav-line:nth-child(2),
    .ramkat-header__nav-line:nth-child(3) {
        top: 0.6vw;
    }

    .ramkat-header__nav-line:nth-child(4) {
        top: 1.1vw;
    }

    .ramkat-header__nav-page-title {
        font-size: 1.6vw;
        padding: 0.5vw 0 0.5vw 2vw;
        margin: 0.5vw 0 0 5.2vw;
    }

    .ramkat-nav-wrap {
        padding: 0 0 0 15%;
    }

    .ramkat-header__nav {
        width: 100%;
        top: 48.5%;
    }

    .ramkat-header__logo-wrap {
        right: 40px;
    }

    .ramkat-header__logo {
        width: 17vw;
    }

    .ramkat-main-nav__wrap {
        width: 50.5%;
    }

    .ramkat-main-nav__title {
        font-size: 9.4vw;
        line-height: 140%;
        letter-spacing: 8px;
    }

    .ramkat-sub-nav__wrap {
        margin-bottom: 30px;
    }

    .ramkat-sub-nav__title {
        font-size: 1.3vw;
        line-height: 136%;
    }

    .ramkat-contact-nav__wrap {
        margin: 40px 0 0 0;
    }

    .ramkat-contact-nav__info {
        font-size: 1.3vw;
    }

    .ramkat-contact-nav__info--address {
        margin-bottom: 25px;
    }

    .ramkat-header__nav-image {
        left: 55%;
    }
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-largest} {
    .ramkat-header__wrap {
        height: 6vw;
    }

    .ramkat-header__nav-header {
        padding: 40px;
    }

    .ramkat-header__nav-hamburger {
        margin-top: 0.7vw;
    }

    .ramkat-header__nav-line:nth-child(2),
    .ramkat-header__nav-line:nth-child(3) {
        top: 0.6vw;
    }

    .ramkat-header__nav-line:nth-child(4) {
        top: 1.3vw;
    }

    .ramkat-header__nav-page-title {
        font-size: 1.65vw;
        letter-spacing: 2px;
        padding: 0.5vw 0 0.5vw 2.25vw;
        margin: 0 0 0 5.5vw;
    }

    .ramkat-header__logo {
        width: 16vw;
    }

    .ramkat-main-nav__wrap {
        width: 46%;
    }

    .ramkat-main-nav__title {
        font-size: 8vw;
        letter-spacing: 6px;
    }

    .ramkat-sub-nav__wrap {
        margin-bottom: 25px;
    }

    .ramkat-sub-nav__title,
    .ramkat-contact-nav__info {
        font-size: 1.25vw;
    }

    .ramkat-contact-nav__wrap {
        margin: 45px 0 0 0;
    }

    .ramkat-contact-nav__info--address {
        margin-bottom: 20px;
    }

    .ramkat-header__nav-image {
        left: 50%;
    }
}

@media screen and (min-width: 1600px) {
    .ramkat-header__wrap {
        height: 100px;
    }

    .ramkat-header__nav-header {
        padding-right: calc((100vw - 1520px) / 2);
        padding-left: calc((100vw - 1520px) / 2);
    }

    .ramkat-header__nav-hamburger {
        margin-top: 11px;
        width: 48px;
    }

    .ramkat-header__nav-line:nth-child(2),
    .ramkat-header__nav-line:nth-child(3) {
        top: 11px;
    }

    .ramkat-header__nav-line:nth-child(4) {
        top: 21px;
    }

    .ramkat-header__nav-page-title {
        font-size: 26.25px;
        padding: 8px 0 8px 36px;
        margin-left: 88px;
    }

    .ramkat-header__logo-wrap {
        right: calc((100vw - 1520px) / 2);
    }

    .ramkat-header__logo {
        width: 257px;
    }

    .ramkat-header__nav {
        max-width: 1522px;
        position: relative;
        margin: 0 auto;
    }

    .ramkat-main-nav__wrap {
        width: 700px;
        width: 55%;
    }

    .ramkat-main-nav__title {
        font-size: 128px;
    }

    .ramkat-sub-nav__wrap {
        width: 600px;
    }

    .ramkat-sub-nav__title,
    .ramkat-contact-nav__info {
        font-size: 20px;
    }

    .ramkat-contact-nav__info--address {
        margin-bottom: 20px;
    }
}

/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {
}
