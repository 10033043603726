.ramkat-home__hero {
    width: 100%;
    height: 100vh;
}

//ios doesn't respect vh
@supports (-webkit-overflow-scrolling: touch) {
    .ramkat-home__hero {
        height: 90vh;
    }
}

.ramkat-home__hero-image-wrap {

}

.ramkat-home__hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.1);
}

.ramkat-home__hero-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: -1;
}

.ramkat-home__hero-details {
    width: 100%;
    color: $white;
    font-family: $din;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    font-size: 10vw;
    letter-spacing: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-decoration: none;
}

.ramkat-home__hero-date,
.ramkat-home__hero-title,
.ramkat-home__hero-cta {
    border-bottom: 3px solid $white;
    line-height: 190%;
}

.ramkat-home__hero-date {

}

.ramkat-home__hero-title {
    hyphens: auto;
}

.ramkat-home__hero-cta {
    color: $white;
    text-decoration: none;
}

////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-smallest} {
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-small} {
}

@media #{$mq-small} and (max-height: 620px) {

    .ramkat-home__hero-details {
        font-size: 7vw;
        letter-spacing: 5px;
    }

}



////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-medium} {

    .ramkat-home__hero-details {
        font-size: 7vw;
        letter-spacing: 5px;
    }

    .ramkat-home__hero-date,
    .ramkat-home__hero-title,
    .ramkat-home__hero-cta {
        border-bottom: 3px solid $white;
        line-height: 250%;
    }

}

@media #{$mq-medium} and (max-height: 850px) {

    .ramkat-home__hero-details {
        font-size: 6.5vw;
    }
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////

@media #{$mq-large} {

    .ramkat-home__hero-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-position: center center;
        background-repeat: repeat;
        background-size: contain;
        z-index: -1;
    }

    .ramkat-home__hero-details {
        font-size: 5vw;
    }
}


////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////

@media #{$mq-largest} {

    .ramkat-home__hero-details {
        font-size: 5vw;
        letter-spacing: 10px;
    }

    .ramkat-home__hero-date,
    .ramkat-home__hero-title,
    .ramkat-home__hero-cta {
        border-bottom: 6px solid $white;
    }

}



////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
@media screen and (min-width: 1600px) {

    .ramkat-home__hero-details {
        font-size: 80px;
        width: 1550px;
    }

}


/***************************************************
 ** Retina x2 - Should be used to replace images. **
 ** Inherits nothing.                             **
 ***************************************************/

@media #{$mq-retina} {}
